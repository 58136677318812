import React, { useState } from "react";
import {
  Menu,
  Grid,
  Header,
  Button,
  MenuItemProps,
  GridColumnProps,
} from "semantic-ui-react";
import ListingCard, {
  ListingCardPlaceholder,
} from "../../../../components/ListingCard";
import Link from "next/link";
import { Listing, ListingPropertyType } from "@listatto/common";

import { getListingTypeDisplayText } from "@listatto/common";
import { SyntheticEvent } from "react";

import styles from "./CurrentListings.module.css";
interface ICurrentListingsProps {
  isLoggedIn: boolean;
  isLoading: boolean;
  listingType: ListingPropertyType;
  currentListings: {
    sale: Listing[];
    lease: Listing[];
  } | null;
}

const listingCardColumnProps: GridColumnProps = {
  computer: 8,
  mobile: 16,
};

const CurrentListings = (props: ICurrentListingsProps) => {
  const { isLoading, currentListings, listingType } = props;
  const [activeItem, setActiveItem] = useState("for-sale");

  const handleItemClick = (e: SyntheticEvent, item: MenuItemProps) => {
    e.preventDefault();
    setActiveItem(item.name!);
  };

  const renderListingCards = () => {
    if (isLoading || !currentListings) {
      return (
        <React.Fragment>
          <ListingCardPlaceholder
            loading
            columnProps={listingCardColumnProps}
          />
          <ListingCardPlaceholder
            loading
            columnProps={listingCardColumnProps}
          />
        </React.Fragment>
      );
    }

    const saleOrLeaseKey = activeItem === "for-sale" ? "sale" : "lease";

    return currentListings[saleOrLeaseKey]?.map((l) => (
      <ListingCard
        key={l.listingId}
        listing={l}
        showAvailability={props.isLoggedIn}
        showDateListed={props.isLoggedIn}
        columnProps={listingCardColumnProps}
        showLike={false}
      />
    ));
  };

  const forDisplayText = activeItem === "for-sale" ? "Sale" : "Rent";

  return (
    <div>
      <Header as={"h2"} dividing>
        Toronto{" "}
        {getListingTypeDisplayText(listingType, {
          plural: true,
        })}{" "}
        for {forDisplayText}
        <Header.Subheader>Newly Listed</Header.Subheader>
      </Header>

      <Menu secondary pointing style={{ borderBottom: "none" }}>
        <Menu.Item
          name="for-sale"
          active={activeItem === "for-sale"}
          onClick={handleItemClick}
        />
        <Menu.Item
          name="for-rent"
          active={activeItem === "for-rent"}
          onClick={handleItemClick}
        />
      </Menu>

      <Grid className={styles.listingGrid}>
        <Grid.Row>{renderListingCards()}</Grid.Row>
        <Grid.Row centered>
          <Grid.Column computer="8" mobile="16">
            {!isLoading && (
              <Link
                href={`/search/${getListingTypeDisplayText(listingType, {
                  plural: true,
                }).toLowerCase()}-${activeItem}/`}
                passHref
              >
                <Button
                  as={"a"}
                  fluid
                  basic
                  style={{ marginTop: 25 }}
                  id="app::search-all-listings"
                  data-ga-event
                >
                  Search All{" "}
                  {getListingTypeDisplayText(listingType, { plural: true })} for{" "}
                  {forDisplayText} &rarr;
                </Button>
              </Link>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default CurrentListings;
export { CurrentListings };
