import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { GetServerSideProps, NextPage } from "next";
import React, { useEffect, useState } from "react";
import { RiMapPinTimeLine, RiPriceTag3Line } from "react-icons/ri";
import { Container, Grid, Header, Menu } from "semantic-ui-react";
import { fetchLayoutProps } from "api/layout";

import {
  Area,
  Listing,
  ListingPropertyType,
  ListingSaleType,
  createAreaQuery,
  createListingQuery,
} from "@listatto/common";
import { getListingTypeDisplayText } from "@listatto/common";

import { getAreas } from "../../api/areas";
import { getListings } from "../../api/listings";
import { useAccountStore } from "../../stores/AccountStore";
import Layout, { LayoutServerProps } from "../Layout";
import styles from "./HomePage.module.css";
import { CurrentListings } from "./components/CurrentListings";
import { FeaturedPlaces } from "./components/FeaturedPlaces";
import { SearchBar } from "./components/SearchBar";

interface IHomePageProps {
  listings: {
    sale: Listing[];
    lease: Listing[];
  };
  areas: Area[];
  layoutProps: LayoutServerProps;
}

const getHomepageListings = async () => {
  const baseQuery = {
    limit: 6,
    area: ["toronto"],
  };

  const saleQuery = createListingQuery({
    ...baseQuery,
    saleType: "SALE" as ListingSaleType,
    propertyType: ListingPropertyType.All,
  });

  const leaseQuery = createListingQuery({
    ...baseQuery,
    saleType: "LEASE" as ListingSaleType,
    propertyType: ListingPropertyType.All,
  });

  const [saleListings, rentListings] = await Promise.all([
    getListings(saleQuery),
    getListings(leaseQuery),
  ]);

  return {
    sale: (saleListings.data || []) as Listing[],
    lease: (rentListings.data || []) as Listing[],
  };
};

export const HomePage: NextPage<IHomePageProps> = observer(function HomePage(
  props
) {
  const { isLoggedIn } = useAccountStore();
  const { listings, areas, layoutProps } = props;
  const [currentListings, setCurrentListings] = useState(listings);

  const [isLoading, setIsLoading] = useState(false);
  const [saleType, setListingSaleType] = useState<ListingSaleType>(
    ListingSaleType.Sale
  );

  useEffect(
    () => {
      const handleListingTypeChange = async () => {
        setIsLoading(true);
        const nextListings = await getHomepageListings();
        setIsLoading(false);
        setCurrentListings(nextListings);
      };
      handleListingTypeChange();
    },
    // eslint-disable-next-line
    []
  );

  const listingTypeDisplay = getListingTypeDisplayText(
    ListingPropertyType.All,
    { plural: true }
  );
  const pageTitle = `Search Toronto ${listingTypeDisplay}`;
  return (
    <React.Fragment>
      <Layout
        title={`${pageTitle} | Listatto`}
        headChildren={
          <>
            <meta
              name="description"
              content="Find your next home with Listatto.com! Search properties and access sold property data for free!"
            />
          </>
        }
        {...layoutProps}
      >
        <Container>
          <Grid>
            <Grid.Row centered className={clsx()}>
              <Grid.Column
                computer={10}
                mobile={16}
                textAlign="center"
                className={clsx(styles.searchHeroInner)}
              >
                <Header
                  as={"h2"}
                  textAlign="center"
                  size="huge"
                  style={{ fontSize: "3em", marginBottom: 0, paddingBottom: 0 }}
                >
                  Listatto
                </Header>
                <Header as="h1" size="small" style={{ padding: 0, margin: 0 }}>
                  {pageTitle}
                </Header>
                <Menu secondary pointing style={{ borderBottom: "none" }}>
                  <Menu.Item
                    as={"a"}
                    href="#sale"
                    name="Sale"
                    icon={
                      <RiPriceTag3Line size={20} style={{ marginRight: 5 }} />
                    }
                    active={saleType === ListingSaleType.Sale}
                    onClick={() => setListingSaleType(ListingSaleType.Sale)}
                  />
                  <Menu.Item
                    as={"a"}
                    href="#rent"
                    name="Rent"
                    icon={
                      <RiMapPinTimeLine size={20} style={{ marginRight: 5 }} />
                    }
                    active={saleType === ListingSaleType.Lease}
                    onClick={() => setListingSaleType(ListingSaleType.Lease)}
                  />
                </Menu>
                <SearchBar saleType={saleType} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <FeaturedPlaces areas={areas} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <CurrentListings
                  listingType={ListingPropertyType.All}
                  isLoading={isLoading}
                  currentListings={currentListings}
                  isLoggedIn={isLoggedIn}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Layout>
    </React.Fragment>
  );
});

export const getServerSideProps: GetServerSideProps<IHomePageProps> = async () => {
  const areaQuery = createAreaQuery({
    fields: ["area_name", "area_slug", "area_image"],
    feature: true,
  });

  const [listings, areasRes, layoutProps] = await Promise.all([
    getHomepageListings(),
    getAreas(areaQuery),
    fetchLayoutProps(),
  ]);

  const areas = areasRes.data as Area[];
  return {
    props: {
      listings,
      areas,
      layoutProps: {
        footerProps: layoutProps.footerProps.data,
      },
    },
  };
};
