import {
  Anchor,
  Button,
  Group,
  Overlay,
  Paper,
  Space,
  Title,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import Link from "next/link";
import React from "react";

import { Area } from "@listatto/common";
import { useMediaQuery } from "@mantine/hooks";

type AreaCardProps = {
  area: Area;
  href?: string;
};

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  inner: {
    position: "relative",
    zIndex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    fontWeight: 400,
    background: theme.white,
    color: theme.black,
    lineHeight: 1.2,
    fontSize: 20,
    marginTop: theme.spacing.xs,
    padding: 10,
    textAlign: "center",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      fontSize: 14,
    },
  },
  category: {
    color: theme.white,
    opacity: 0.7,
    fontWeight: 700,
    textTransform: "uppercase",
  },
}));

export function AreaCard({ area }: AreaCardProps) {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  return (
    <Paper
      shadow="md"
      p="xl"
      radius="md"
      sx={{
        backgroundImage: `url(${process.env.NEXT_PUBLIC_CDN_HOST}/${area.area_image})`,
      }}
      className={classes.card}
    >
      <Overlay w="100%" color="#000" opacity={0.25} zIndex={1} />

      <div className={classes.inner}>
        <Title order={3} className={classes.title}>
          <Link href={`/areas/${area.area_slug}`}>
            <Anchor
              sx={{
                color: "black",
              }}
            >
              {area.area_name}
            </Anchor>
          </Link>
        </Title>
        <Space h="lg" />
        <Group align={"center"} position="center">
          <Link
            passHref
            href={`/search/listings-for-sale/in/${area.area_slug!}`}
          >
            <Button
              variant="white"
              color="dark"
              size={isSmallScreen ? "xs" : undefined}
            >
              For Sale
            </Button>
          </Link>

          <Link
            passHref
            href={`/search/listings-for-rent/in/${area.area_slug!}`}
          >
            <Button
              variant="white"
              color="dark"
              size={isSmallScreen ? "xs" : undefined}
              fz={isSmallScreen ? 10 : undefined}
            >
              For Rent
            </Button>
          </Link>
        </Group>
      </div>
    </Paper>
  );
}
