import {
  ApiResponse,
  Area,
  AreaQuery,
  ListingAreaStatsQuery,
} from "@listatto/common";

import { fetchApi } from "./fetch";

export async function fetchAreas(query: AreaQuery) {
  return fetchApi("/api/v2/areas", {
    query,
  }) as ApiResponse<Area[]>;
}

export const getAreas = fetchAreas;

export async function fetchAreasForAddressId(addressId: number) {
  return fetchApi("/api/v2/areas/areas-for-address/" + addressId, {});
}

export async function fetchAreasForListingId(listingId: number) {
  return fetchApi(
    "/api/v2/areas/areas-for-listing/" + listingId,
    {}
  ) as ApiResponse<Area[]>;
}

export async function fetchAreaHierarchyForSlug(slug: string) {
  return fetchApi("/api/v2/areas/hierarchy/" + slug, {}) as ApiResponse<{
    parents: Area[];
    children: Area[];
  }>;
}

export async function fetchAreaListingStats(query: ListingAreaStatsQuery) {
  return fetchApi("/api/v2/areas/stats/" + query.slug, {
    query,
  });
}
