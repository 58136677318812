import { Carousel } from "@mantine/carousel";
import { useIsMobile } from "hooks/use-is-mobile";
import React from "react";
import { Divider, Header } from "semantic-ui-react";

import { Area } from "@listatto/common";

import { AreaCard } from "../../../AreaCard";
import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

type FeaturedPlacesProps = {
  areas: Area[];
};

export const FeaturedPlaces: React.FC<FeaturedPlacesProps> = (props) => {
  const { areas } = props;
  const theme = useMantineTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  return (
    <React.Fragment>
      <Header as="h2">Featured Neighbourhoods</Header>
      <Divider />
      <Carousel
        loop
        align="start"
        slideGap={"lg"}
        height={isSmallScreen ? 200 : 300}
        slidesToScroll={isSmallScreen ? 2 : 3}
        slideSize="33%"
        dragFree
        withControls={!isSmallScreen}
        breakpoints={[
          {
            maxWidth: "md",
            slideSize: "50%",
            slideGap: "xs",
          },
        ]}
      >
        {areas.map((a) => (
          <Carousel.Slide key={a.area_slug} h="100%">
            <AreaCard
              area={a}
              // h={isMobile ? 200 : undefined}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </React.Fragment>
  );
};
