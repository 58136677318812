import React, {
  useCallback,
  useState,
  useRef,
  SyntheticEvent,
  useEffect,
} from "react";
import debounce from "lodash/debounce";
import isArray from "lodash/isArray";
import {
  Search,
  Button,
  Form,
  Placeholder,
  SearchResultData,
  SearchProps,
  Icon,
} from "semantic-ui-react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import Head from "next/head";
import {
  getPropertyAndSaleTypeDisplayText,
  ListingSaleType,
} from "@listatto/common";
import styles from "./SearchBar.module.css";
import cx from "clsx";
import { fetchApi } from "../../../../api/fetch";
import { useMemo } from "react";
import {
  createListingQuery,
  Listing,
  ListingPropertyType,
} from "@listatto/common";
import { useRouter } from "next/router";
import { RiMapLine, RiMapPin2Line } from "react-icons/ri";
import { buffer, bbox } from "@turf/turf";

const MIN_CHAR = 4;

interface ISearchBarProps {
  saleType: ListingSaleType;
}

enum SearchBarType {
  Address = "address",
  Area = "area",
  Mls = "mls",
}

const getCategoryIcon = (category: string) => {
  switch (category.toLowerCase()) {
    case SearchBarType.Area:
      return <RiMapLine />;
    case SearchBarType.Address:
    case SearchBarType.Mls:
      return <RiMapPin2Line />;
    default:
      return null;
  }
};

const renderCategoryContent = (category: string) => {
  const cat = category.toLowerCase();
  if (cat === "address") {
    return (
      <>
        Address{" "}
        <small>
          - powered by <strong>Google</strong>
        </small>
      </>
    );
  }
  return category;
};

export const SearchBar = (props: ISearchBarProps) => {
  const [results, setResults] = useState<boolean>(false);
  const [isLoadingAreasMLS, setIsLoadingAreasMLS] = useState(false);
  const [query, setQuery] = useState("");
  const [isFocused, setIsFocused] = useState(true);
  const submitRef = useRef<Button>();
  const router = useRouter();
  const [areas, setAreas] = useState<any[] | null>(null);
  const [mls, setMls] = useState<any[] | null>(null);

  const {
    init,
    suggestions: { data: addresses, status },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location:
        process.browser && window.google
          ? new google.maps.LatLng({
              // TODO: replace with tenant's preferred bias
              lng: -79.345005831,
              lat: 43.63463647,
            })
          : undefined,
      radius: 200 * 1000,
    },
    initOnMount: false,
    debounce: 300,
  });

  useEffect(() => {
    if (!process.browser) return;

    if (document.getElementById("google-map-api")) {
      if (window.google) return init();
      return;
    }

    const scriptTag = document.createElement("script");
    scriptTag.id = "google-map-api";
    scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY}&libraries=places`;
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.onload = () => init();

    document.head.appendChild(scriptTag);
  }, [init]);

  const isLoadingAddresses = query.length && status === "";

  const isLoading = isLoadingAddresses || isLoadingAreasMLS;

  const debouncedSearch = useCallback(
    debounce<any>(async (q: string) => {
      setIsLoadingAreasMLS(true);
      clearSuggestions();
      setValue(q);

      fetchApi("/api/v2/search", {
        query: {
          q,
          d: "area,mls",
        },
      }).then(({ data }) => {
        setIsLoadingAreasMLS(false);

        if (data.areas) {
          setAreas(
            data.areas.map((a: any) => {
              return {
                key: a.area_name,
                title: a.area_name,
                data: a,
                type: SearchBarType.Area,
              };
            })
          );
        } else {
          setAreas([
            {
              title: "No results found",
            },
          ]);
        }

        if (data.mls) {
          setMls(
            data.mls.map((m: Listing) => {
              return {
                key: m.mlsid,
                title: `${m.mlsid} - ${m.fullAddress}`,
                data: m,
                type: SearchBarType.Mls,
              };
            })
          );
        }

        setResults(true);
      });
    }, 500),
    []
  );

  const handleOnSearchChange = (e: SyntheticEvent, data: SearchProps) => {
    e.preventDefault();
    const q = data.value;
    if (q && q.length >= MIN_CHAR) {
      setQuery(q);
      setIsLoadingAreasMLS(true);
      debouncedSearch(q);
    } else {
      setQuery("");
      setResults(false);
    }
  };

  const gotoHref = (href: string) => {
    const { plainKebab } = getPropertyAndSaleTypeDisplayText(
      ListingPropertyType.All,
      props.saleType,
      {
        plural: true,
      }
    );
    const nextHref = `/search/${plainKebab}/${href}`;
    router.push(nextHref);
  };

  // TODO: handle the selection of a search result
  const handleResultSelect = async (
    _e: SyntheticEvent,
    { result }: SearchResultData
  ) => {
    const data = result.data;
    const type: SearchBarType = result.type;
    submitRef.current?.focus();

    switch (type) {
      case SearchBarType.Address:
        const geocodeResults = await getGeocode({
          address: data.description,
        });
        const geocodeResult = geocodeResults[0];
        const latLng = await getLatLng(geocodeResult);
        const [neLng, neLat, swLng, swLat] = bbox(
          buffer(
            {
              type: "Point",
              coordinates: [latLng.lng, latLng.lat],
            },
            500,
            { units: "meters" }
          )
        );

        const href = createListingQuery({
          location: latLng,
          bounds: {
            ne: { lat: neLat, lng: neLng },
            sw: { lat: swLat, lng: swLng },
          },
          zoomTo: 14.5,
        }).toString();

        const locality = geocodeResult.address_components
          .find((a) => a.types.includes("locality"))
          ?.short_name.toLowerCase();

        gotoHref(`${locality}${href}`);
        break;
      case SearchBarType.Area:
        gotoHref(`in/${data.area_slug}`);
        break;
      case SearchBarType.Mls:
        router.push(data.linkTo);
        break;
    }
  };

  const handleSubmit = () => {};

  const shouldShowNoResults = !results && !isLoading && query.length > 0;

  const categoryResults = useMemo(() => {
    const catResults = {
      [SearchBarType.Area]: {
        name: "Area",
        results: [
          {
            key: 0,
            title: "loading",
          },
        ],
      },
      [SearchBarType.Address]: {
        name: "Address",
        results: [
          {
            key: 0,
            title: "loading",
          },
        ],
      },
    };

    if (!results) return catResults;

    if (isArray(areas) && !mls) {
      catResults[SearchBarType.Area].results = areas;
    }

    if (isArray(addresses) && !mls) {
      // @ts-ignore
      catResults[SearchBarType.Address].results = addresses.map((a) => {
        return {
          type: SearchBarType.Address,
          key: a.place_id,
          title: a.description,
          data: a,
        };
      });
    }

    if (isArray(mls)) {
      return {
        [SearchBarType.Mls]: {
          name: "MLS",
          results: mls,
        },
      };
    }
    return catResults;
  }, [addresses, areas, mls, results]);

  const shouldOpen = (query.length > 0 && isFocused) || shouldShowNoResults;

  return (
    <>
      <div>
        <Form
          onSubmit={handleSubmit}
          id="app::form-search-submit"
          data-ga-event
        >
          <Search
            className={cx(styles.searchBar)}
            input={{
              icon: "search",
              iconPosition: "left",
              fluid: true,
              placeholder: "Search by Address, Area, MLS#",
              autoFocus: true,
              name: "search",
            }}
            loading={isLoading}
            size="big"
            fluid
            minCharacters={MIN_CHAR}
            selectFirstResult
            onSearchChange={handleOnSearchChange}
            showNoResults={shouldShowNoResults}
            category
            noResultsMessage="No results found."
            results={categoryResults}
            categoryLayoutRenderer={({ categoryContent, resultsContent }) => (
              <span className={cx(styles.categoryResults)}>
                <div className={cx("name", styles.categoryHeader)}>
                  <Icon>{getCategoryIcon(categoryContent)}</Icon>
                  {renderCategoryContent(categoryContent)}
                </div>
                <div className={cx("results", styles.result)}>
                  {resultsContent}
                </div>
              </span>
            )}
            open={shouldOpen}
            onResultSelect={handleResultSelect}
            resultRenderer={({ title }) => {
              if (title === "loading")
                return (
                  <Placeholder>
                    <Placeholder.Paragraph>
                      <Placeholder.Line />
                    </Placeholder.Paragraph>
                  </Placeholder>
                );
              return <p>{title}</p>;
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
          />
        </Form>
      </div>
    </>
  );
};
