import { FooterProps } from "components/Footer";

import { fetchItem } from "./directus";

export async function fetchLayoutProps() {
  const footerProps = await fetchItem<FooterProps>("footer", {});
  return {
    footerProps,
  };
}
