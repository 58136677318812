import {
  ApiResponse,
  Listing,
  ListingQuery,
  createListingQuery,
} from "@listatto/common";

import { fetchApi } from "./fetch";

type FetchListingsOpts = {
  auth?: string;
};
export async function fetchListings(
  query: ListingQuery,
  opts?: FetchListingsOpts
) {
  return fetchApi("/api/v2/listings", {
    query,
    ...opts,
  }) as ApiResponse<Listing[]>;
}

export async function fetchLikedListingIds() {
  return fetchApi("/api/v2/listings/likes", {}) as ApiResponse<number[]>;
}

export const getListings = fetchListings;

export async function fetchListingPins(query: ListingQuery) {
  const fields = ["listingId", "listPrice", "saleType"];
  if (query.sold) {
    fields.push("soldPrice");
  }
  return fetchApi("/api/v2/listings", {
    query: createListingQuery({
      ...query.toJSON(),
      fields,
      geojson: true,
      all: true,
    }),
  }) as ApiResponse<GeoJSON.Feature<GeoJSON.Point>[]>;
}
export const getListingPins = fetchListingPins;

export function likeListing(listingId: string | number) {
  return fetchApi("/api/likes", {
    method: "post",
    body: {
      listingId,
    },
  });
}
export function unlikeListing(listingId: string | number) {
  return fetchApi(`/api/likes/${listingId}`, {
    method: "delete",
  });
}
